import {
    CognitoIdentityProviderClient,
    ListUsersCommand,
    type UserType,
} from "@aws-sdk/client-cognito-identity-provider";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useSearchParams } from "react-router-dom";

const HomePage = (): ReactNode => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState<UserType[] | null>(null);
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (
            !(
                hasAuthParams() ||
                auth.isAuthenticated ||
                auth.activeNavigator ||
                auth.isLoading ||
                hasTriedSignin
            )
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }

        if (auth.isAuthenticated) {
            if (searchParams.has("code")) {
                setSearchParams();
            }
        }
    }, [auth, hasTriedSignin, setSearchParams, searchParams]);

    const cognitoClient = useMemo(() => {
        if (!auth?.user?.id_token) {
            return null;
        }

        const cognitoClient = new CognitoIdentityProviderClient({
            region: "us-east-1",
            credentials: fromCognitoIdentityPool({
                clientConfig: { region: "us-east-1" }, // Configure the underlying CognitoIdentityClient.
                identityPoolId: "us-east-1:aafaa54c-fef6-4284-b495-71b1092c2c10",
                logins: {
                    "cognito-idp.us-east-1.amazonaws.com/us-east-1_J5OfCWVTY": auth.user.id_token,
                },
            }),
        });
        return cognitoClient;
    }, [auth?.user?.id_token]);

    if (auth.isLoading) {
        return <div>Signing you in/out...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }
    return (
        <>
            <ul>
                <li>{auth.user?.profile?.email}</li>
                <li>{auth.user?.profile?.given_name}</li>
                <li>{auth.user?.profile?.family_name}</li>
            </ul>
            <Button
                onClick={async () => {
                    if (!cognitoClient) {
                        return;
                    }

                    const response = await cognitoClient.send(
                        new ListUsersCommand({
                            UserPoolId: "us-east-1_J5OfCWVTY",
                        }),
                    );
                    if (response.Users) {
                        setUsers(response.Users);
                    }
                }}
            >
                Do Cognito stuff
            </Button>
            <Button onClick={() => void auth.removeUser()}>Log out</Button>
            {users && (
                <List>
                    {users.map((user) => {
                        const email =
                            user.Attributes?.find((a) => a.Name === "email")?.Value ?? "unkown";
                        const firstName =
                            user.Attributes?.find((a) => a.Name === "given_name")?.Value ??
                            "unkown";
                        const lastName =
                            user.Attributes?.find((a) => a.Name === "family_name")?.Value ??
                            "unkown";
                        return (
                            <ListItem key={user.Username}>
                                <ListItemText primary={`${email} ${lastName}, ${firstName}`} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </>
    );
};

export default HomePage;
