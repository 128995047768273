import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import MultiProvider from "@/components/MultiProvider/index.ts";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import { ConfirmProvider } from "react-confirm-hook";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { RouterProvider } from "react-router-dom";
import { router } from "./router.js";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Root element missing");
}

const theme = createTheme();
const queryClient = new QueryClient();
const root = createRoot(container);

root.render(
    <StrictMode>
        <MultiProvider
            providerCreators={[
                (children) => (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                ),
                (children) => (
                    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
                ),
                (children) => <SnackbarProvider>{children}</SnackbarProvider>,
                (children) => <ConfirmProvider>{children}</ConfirmProvider>,
                (children) => (
                    <AuthProvider
                        authority="https://cognito-idp.us-east-1.amazonaws.com/us-east-1_J5OfCWVTY/"
                        client_id="nsvtdlsuh74ufd5acdtfs8ci6"
                        redirect_uri={window.location.origin}
                    >
                        {children}
                    </AuthProvider>
                ),
            ]}
        >
            <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />
        </MultiProvider>
    </StrictMode>,
);
